import { CALL_SOCKET_API } from "../middleware/agent-api";

export const PHR_READ_REQUEST = "PHR_READ_REQUEST";
export const PHR_READ_SUCCESS = "PHR_READ_SUCCESS";
export const PHR_READ_FAILURE = "PHR_READ_FAILURE";
export const readPhr = (data = {}, callBack) => ({
  [CALL_SOCKET_API]: {
    types: [PHR_READ_REQUEST, PHR_READ_SUCCESS, PHR_READ_FAILURE],
    endpoint: "readPhr",
    data,
    callBack
  }
});

export const PHR_DOWNLOAD_REQUEST = "PHR_DOWNLOAD_REQUEST";
export const PHR_DOWNLOAD_SUCCESS = "PHR_DOWNLOAD_SUCCESS";
export const PHR_DOWNLOAD_FAILURE = "PHR_DOWNLOAD_FAILURE";
export const downloadPdfPhr = id => ({
  [CALL_SOCKET_API]: {
    types: [PHR_DOWNLOAD_REQUEST, PHR_DOWNLOAD_SUCCESS, PHR_DOWNLOAD_FAILURE],
    endpoint: "downloadPdfPhr",
    nonBlocking: true,
    data: { _id: id }
  }
});

export const PHR_LAB_SET_DATA = "PHR_LAB_SET_DATA";
export const setLabData = (key, value) => ({
  type: PHR_LAB_SET_DATA,
  key,
  value
});

export const PHR_UPDATE_REQUEST = "PHR_UPDATE_REQUEST";
export const PHR_UPDATE_SUCCESS = "PHR_UPDATE_SUCCESS";
export const PHR_UPDATE_FAILURE = "PHR_UPDATE_FAILURE";
export const updatePhr = (data = {}) => ({
  [CALL_SOCKET_API]: {
    types: [PHR_UPDATE_REQUEST, PHR_UPDATE_SUCCESS, PHR_UPDATE_FAILURE],
    endpoint: "updatePhr",
    data
  }
});
