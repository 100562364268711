// cSpell:ignore normalizr
import {
  ITEM_GET_REQUEST,
  ITEM_GET_SUCCESS,
  ITEM_GET_FAILURE
} from "../actions";

const item = (
  state = {
    model: "",
    loading: false,
    data: null
  },
  action
) => {
  switch (action.type) {
    case ITEM_GET_REQUEST:
      return { ...state, model: action.model, loading: true, data: null };
    case ITEM_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data:
          ITEM_GET_SUCCESS === action.type
            ? action.response.data[0]
            : action.response.data
      };
    case ITEM_GET_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default item;
