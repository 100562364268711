import React, { Component } from "react";
import PropTypes from "prop-types";
import range from "lodash/range";
import ListItem from "./ListItem";

class List extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    records: PropTypes.array.isRequired,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        type: PropTypes.string,
        icon: PropTypes.string,
        handler: PropTypes.func.isRequired,
        conditional: PropTypes.func
      })
    ),
    page: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
    setPage: PropTypes.func,
    sortField: PropTypes.object,
    onSort: PropTypes.func,
    highlightFields: PropTypes.object,
    highlightTextPath: PropTypes.object,
    highlightText: PropTypes.object
  };

  sortClick = event => {
    event.preventDefault();
    this.props.onSort({
      [event.target.getAttribute("data-field")]:
        "a" === event.target.getAttribute("data-sort") ? 1 : -1
    });
  };

  render = () => {
    const {
        fields,
        records,
        actions,
        page = 1,
        perPage,
        total,
        setPage,
        sortField,
        highlightFields,
        highlightTextPath,
        highlightText
      } = this.props,
      pageCount = total ? Math.ceil(total / perPage) : 1,
      pageMax = page + 2,
      pageMin = page - 2;
    let leftDotsNotShown = true,
      rightDotsNotShown = true;

    if (!records) {
      return null;
    }
    return (
      <table id="listTable" className="ui sortable striped celled table">
        <thead>
          <tr>
            {Object.keys(fields).map(field => {
              const fieldConfig = fields[field],
                isSortable = fieldConfig.sortable,
                isSorted = isSortable && sortField && !!sortField[field],
                isAscending = isSorted && 1 === sortField[field];
              return (
                <th
                  key={field}
                  className={
                    isSorted
                      ? isAscending
                        ? "sorted ascending"
                        : "sorted descending"
                      : ""
                  }
                >
                  {isSortable && (
                    <span
                      className="link"
                      onClick={this.sortClick}
                      data-field={field}
                      data-sort={isAscending ? "d" : "a"}
                    >
                      {fieldConfig.title}
                    </span>
                  )}
                  {!isSortable && fieldConfig.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {records.length > 0 &&
            records.map((record, index) => (
              <ListItem
                key={record._id}
                fields={fields}
                record={record}
                actions={actions}
                serial={total ? (page - 1) * perPage + index + 1 : index + 1}
                highlightFields={highlightFields}
                highlightTextPath={highlightTextPath}
                highlightText={highlightText}
              />
            ))}
          {records.length <= 0 && (
            <tr>
              <td colSpan={Object.keys(fields).length}>No records</td>
            </tr>
          )}
        </tbody>
        {/* Pagination - Begin */}
        {!!total && total > perPage && (
          <tfoot>
            <tr>
              <th colSpan={Object.keys(fields).length}>
                <div className="ui left floated large label">
                  {(page - 1) * perPage + 1} -{" "}
                  {page * perPage > total ? total : page * perPage} / {total}
                </div>
                <div
                  id="listPagination"
                  className="ui right floated pagination menu"
                >
                  {range(1, pageCount + 1).map(i => {
                    if (i === page) {
                      return (
                        <span className="item active" key={i}>
                          {i}
                        </span>
                      );
                    }
                    if (
                      1 === i ||
                      (i <= pageMax && i >= pageMin) ||
                      i === pageCount
                    ) {
                      return (
                        <button
                          className="item"
                          key={i}
                          onClick={() => setPage(i)}
                        >
                          {i}
                        </button>
                      );
                    }
                    if (
                      i < pageCount &&
                      ((i < page && leftDotsNotShown) ||
                        (i > page && rightDotsNotShown))
                    ) {
                      if (i > page) {
                        rightDotsNotShown = false;
                      } else {
                        leftDotsNotShown = false;
                      }
                      return (
                        <span className="item" key={i}>
                          ...
                        </span>
                      );
                    }
                    return null;
                  })}
                  {1 === page && (
                    <span className="icon item disabled">
                      <i className="left chevron icon" />
                    </span>
                  )}
                  {page > 1 && (
                    <button
                      className="icon item"
                      onClick={() => setPage(page - 1)}
                    >
                      <i className="left chevron icon" />
                    </button>
                  )}
                  {pageCount === page && (
                    <span className="icon item disabled">
                      <i className="right chevron icon" />
                    </span>
                  )}
                  {page < pageCount && (
                    <button
                      className="icon item"
                      onClick={() => setPage(page + 1)}
                    >
                      <i className="right chevron icon" />
                    </button>
                  )}
                </div>
              </th>
            </tr>
          </tfoot>
        )}
        {/* Pagination - End */}
      </table>
    );
  };
}

export default List;
