const config = {
  socketRequestTimeout: 20000, // milliseconds
  reCaptchaSiteKey: "",
  fromEmail: "admin@mayamd.ai",
  minPasswordScore: 60 /* out of 100 */
};
if (process.env.NODE_ENV === "development") {
  config.apiUrl = "http://localhost:8080/";
}

export default config;
