// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel } from "../../actions/listing";
import HspSearch from "./HspSearch";
import Listing from "../Listing";

class HspListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    setItemModel: PropTypes.func.isRequired
  };

  model = "hsp";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history } = this.props,
      fields = {
        serial: { title: "#", type: "serial" },
        _id: { title: "ID", link: "/hsp/phr/" },
        name: { title: "Name", sortable: true },
        email: { title: "Email", sortable: true },
        phone: { title: "Phone" },
        active: { title: "Active", type: "bool", sortable: true },
        created: {
          title: "Created",
          type: "date",
          format: "YYYY-MM-DD",
          sortable: true
        },
        actions: { title: "Actions", type: "actions" }
      },
      actions = [
        {
          name: "Edit",
          type: "primary",
          icon: "edit",
          handler: id => {
            history.push(`/${this.model}/edit/${id}`);
          }
        }
      ];
    return (
      <Listing
        title="HSPs"
        model={this.model}
        mainField="name"
        // list config
        fields={fields}
        actions={actions}
        highlightFields={{ name: 1, email: 1 }}
        // search form component
        ItemSearch={HspSearch}
        onAdd={() => {
          history.push(`/${this.model}/add`);
        }}
        simpleSearchTitle="HSP"
        getSimpleSearch={search => ({
          name: search
        })}
      />
    );
  }
}

export default withRouter(
  connect(
    null,
    {
      setItemModel
    }
  )(HspListing)
);
