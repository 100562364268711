import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  formSetData,
  formResetData,
  formSetError,
  formResetError,
  switchTab,
  readHsp
} from "../../actions";
import ScreenTitle from "../../components/ScreenTitle";
import AgentForm from "../AgentForm/Form";
import HspForm from "../HspForm/Form";

class Hsp extends Component {
  static propTypes = {
    // Injected by React-Redux
    tab: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    agent: PropTypes.object,
    hsp: PropTypes.object,
    formSetData: PropTypes.func.isRequired,
    formResetData: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    switchTab: PropTypes.func.isRequired,
    readHsp: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const { hsp, switchTab, readHsp } = this.props;
    if (!hsp) {
      readHsp();
    }
    switchTab("profile");
  };

  render = () => {
    const { agent, hsp, tab, switchTab } = this.props;

    return (
      <article className="thirteen wide column">
        <header className="ui dividing header">
          <div className="ui two column grid">
            <ScreenTitle title="Account" className="column" />
          </div>
        </header>
        <div className="ui">
          <div className="ui top attached tabular menu">
            <div
              className={"item" + ("profile" === tab ? " active" : "")}
              onClick={() => switchTab("profile")}
            >
              Profile
            </div>
            <div
              className={"item" + ("hsp" === tab ? " active" : "")}
              onClick={() => switchTab("hsp")}
            >
              HSP
            </div>
          </div>
          <div
            className={
              "ui bottom attached tab segment" +
              ("profile" === tab ? " active" : "")
            }
          >
            {agent ? (
              <AgentForm agent={agent} edit={true} profile={true} />
            ) : (
              <div className="ui segment">
                <div className="ui hidden divider" />
                <div className="ui active loader" />
                <div className="ui hidden divider" />
              </div>
            )}
          </div>
          <div
            className={
              "ui bottom attached tab segment" +
              ("hsp" === tab ? " active" : "")
            }
          >
            {hsp ? (
              <HspForm hsp={hsp} />
            ) : (
              <div className="ui segment">
                <div className="ui hidden divider" />
                <div className="ui active loader" />
                <div className="ui hidden divider" />
              </div>
            )}
          </div>
        </div>
      </article>
    );
  };
}

const mapStateToProps = state => ({
  tab: state.ui.tab,
  form: state.ui.form,
  agent: state.agent.data,
  hsp: state.hsp.data
});

export default connect(
  mapStateToProps,
  {
    formSetData,
    formResetData,
    formSetError,
    formResetError,
    switchTab,
    readHsp
  }
)(Hsp);
