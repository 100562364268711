import {
  AUTOCOMPLETE_RESET,
  AUTOCOMPLETE_SELECTED,
  AUTOCOMPLETE_SEARCH,
  AUTOCOMPLETE_REQUEST,
  AUTOCOMPLETE_SUCCESS,
  AUTOCOMPLETE_FAILURE,
  AUTOCOMPLETE_GET_REQUEST,
  AUTOCOMPLETE_GET_SUCCESS,
  AUTOCOMPLETE_GET_FAILURE
} from "../../actions/ui/autocomplete";

const autocomplete = (
  state = { loading: false, search: "", results: [], selected: null },
  action
) => {
  switch (action.type) {
    case AUTOCOMPLETE_SEARCH:
      return { ...state, search: action.search };
    case AUTOCOMPLETE_RESET:
      return { loading: false, search: "", results: [], selected: null };
    case AUTOCOMPLETE_SELECTED:
      return { ...state, selected: action.item };
    case AUTOCOMPLETE_REQUEST:
    case AUTOCOMPLETE_GET_REQUEST:
      return { ...state, loading: true };
    case AUTOCOMPLETE_SUCCESS:
      return { ...state, results: action.response.data, loading: false };
    case AUTOCOMPLETE_GET_SUCCESS:
      return {
        ...state,
        selected: action.response.data[0] || null,
        loading: false
      };
    case AUTOCOMPLETE_FAILURE:
    case AUTOCOMPLETE_GET_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default autocomplete;
