// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TopBar from "../TopBar";
import LeftBarContainer from "../LeftBarContainer";
import Login from "../Login";
import Routes from "../../components/Routes";
import BottomBar from "../../components/BottomBar";
import { resetStatusMessage } from "../../actions/ui/statusMessage";

class App extends Component {
  static propTypes = {
    // Injected by React-Router
    location: PropTypes.object.isRequired,
    // Injected by React-Redux
    auth: PropTypes.shape({
      loggedIn: PropTypes.bool,
      user: PropTypes.object
    }).isRequired,
    loading: PropTypes.bool,
    statusMessage: PropTypes.shape({
      type: PropTypes.string,
      timeout: PropTypes.number,
      message: PropTypes.string.isRequired,
      description: PropTypes.string
    }),
    resetStatusMessage: PropTypes.func.isRequired
  };

  messageTimeoutHandle = null;

  componentWillMount = () => {
    if ("undefined" !== typeof Notification) {
      // Request permission if not already granted
      if ("granted" !== Notification.permission) {
        Notification.requestPermission(status => {
          console.info("Browser notification permission:", status);
          if (Notification.permission !== status) {
            Notification.permission = status;
          }
        });
      }
    } else {
      console.warn("Browser does not support notifications");
    }
  };

  handleDismissClick = event => {
    event.preventDefault();
    this.props.resetStatusMessage();
  };

  formatTitle = title => {
    return (
      title
        // insert a space before all caps
        .replace(/([A-Z])/g, " $1")
        // uppercase the first character
        .replace(/^./, function(str) {
          return str.toUpperCase();
        })
    );
  };

  renderStatusMessage = () => {
    const { statusMessage, resetStatusMessage } = this.props;
    if (!statusMessage) {
      return null;
    }

    // clear previous timeout, if any
    if (this.messageTimeoutHandle) {
      clearTimeout(this.messageTimeoutHandle);
      this.messageTimeoutHandle = null;
    }

    // set timeout if specified
    if (statusMessage.timeout) {
      this.messageTimeoutHandle = setTimeout(
        resetStatusMessage,
        statusMessage.timeout * 1000 // should be in milliseconds here
      );
    }

    let classNames = "";
    switch (statusMessage.type) {
      case "success":
        classNames = "ui positive message container";
        break;
      case "error":
        classNames = "ui negative message container";
        break;
      case "warning":
        classNames = "ui yellow message container";
        break;
      default:
        classNames = "ui blue message container";
        break;
    }

    return (
      <div className="row">
        <div className={classNames}>
          <i className="close icon" onClick={this.handleDismissClick} />
          <div className="header">{statusMessage.message}</div>
          {statusMessage.description && <p>{statusMessage.description}</p>}
        </div>
      </div>
    );
  };

  render = () => {
    const { location, loading, auth } = this.props,
      currentPage = location.pathname.split("/", 2)[1],
      loggedIn = auth.loggedIn,
      isAdmin = auth.user ? "admin" === auth.user.group : false,
      isStaff = auth.user ? "staff" === auth.user.group : false;
    let mode = "login";

    if (location.search && "?mode=register" === location.search) {
      mode = "register";
    }
    document.title =
      "Maya HSP Portal - " +
      (loggedIn
        ? currentPage
          ? this.formatTitle(currentPage)
          : "Dashboard"
        : this.formatTitle(mode));
    return (
      <div className="ui grid">
        <div className="row">
          <TopBar />
        </div>
        {this.renderStatusMessage()}
        <div className={"row" + (loading ? " ui loading segment" : "")}>
          {loggedIn && <LeftBarContainer />}
          {loggedIn && <Routes isAdmin={isAdmin} isStaff={isStaff} />}
          {!loggedIn && <Login mode={mode} />}
        </div>
        <div className="row">
          <BottomBar />
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  auth: state.auth,
  loading: state.ui.loading,
  statusMessage: state.ui.statusMessage
});

export default withRouter(
  connect(mapStateToProps, {
    resetStatusMessage
  })(App)
);
