const redirection = {
  createAgent: [{ path: "/agent" }],
  updateAgent: [{ path: "/agent" }],
  createHsp: [{ path: "/hsp", groups: { admin: 1, staff: 1 } }],
  updateHsp: [{ path: "/hsp", groups: { admin: 1, staff: 1 } }],
  createDnd: [{ path: "/dnd", groups: { admin: 1 } }],
  readPhr: [{ path: "/phr/view/id", types: { LabTechnician: 1 } }]
};

export default redirection;
