import { LOGIN_MODE, SET_LOGIN_MESSAGE } from "../../actions/ui/login";
import {
  RECOVER_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS
} from "../../actions/agent";

// Login form state
const login = (
  state = { mode: "login", loginMessage: "", resetSuccess: false },
  action
) => {
  switch (action.type) {
    case LOGIN_MODE:
      return {
        ...state,
        mode: action.mode,
        resetSuccess: false
      };
    case SET_LOGIN_MESSAGE:
      return {
        ...state,
        loginMessage: action.message
      };
    case RECOVER_PASSWORD_SUCCESS:
      return action.response.ok
        ? {
            ...state,
            loginMessage: action.response.message
          }
        : state;
    case RESET_PASSWORD_SUCCESS:
      return action.response.ok
        ? {
            ...state,
            loginMessage: "",
            resetSuccess: true
          }
        : state;
    default:
      return state;
  }
};

export default login;
