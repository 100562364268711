import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resetStatusMessage,
  formSetError,
  formResetError,
  createItem,
  updateItem
} from "../../actions";
import * as validations from "../../lib/validations";

class Form extends Component {
  static propTypes = {
    hsp: PropTypes.object,
    onCancel: PropTypes.func,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    userGroup: PropTypes.string.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    createItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired
  };

  model = "hsp";

  componentWillMount = () => {
    const { resetStatusMessage, formResetError } = this.props;
    resetStatusMessage();
    formResetError();
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const {
        hsp,
        formSetError,
        formResetError,
        createItem,
        updateItem
      } = this.props,
      name = this.nameField.value.trim(),
      email = this.emailField.value.trim(),
      address1 = this.address1Field.value.trim(),
      address2 = this.address2Field.value.trim(),
      city = this.cityField.value.trim(),
      state = this.stateField.value.trim(),
      zip = this.zipField.value.trim(),
      country = this.countryField.value.trim(),
      phone = this.phoneField.value.trim();
    let hasError = false;
    formResetError();
    if (name && !validations.standardText(name)) {
      formSetError("name");
      hasError = true;
    }
    if (email && !validations.email(email)) {
      formSetError("email");
      hasError = true;
    }
    if (address1 && !validations.standardText(address1)) {
      formSetError("address1");
      hasError = true;
    }
    if (address2 && !validations.standardText(address2)) {
      formSetError("address2");
      hasError = true;
    }
    if (city && !validations.standardText(city)) {
      formSetError("city");
      hasError = true;
    }
    if (state && !validations.standardText(state)) {
      formSetError("state");
      hasError = true;
    }
    if (zip && !validations.standardText(zip)) {
      formSetError("zip");
      hasError = true;
    }
    if (country && !validations.standardText(country)) {
      formSetError("country");
      hasError = true;
    }
    if (phone && !validations.standardText(phone)) {
      formSetError("phone");
      hasError = true;
    }
    if (!hasError) {
      const data = {};
      if (name) {
        data.name = name;
      }
      if (email) {
        data.email = email;
      }
      if (this.activeField) {
        data.active = this.activeField.checked;
      }
      if (address1 || address2 || city || state || zip || country) {
        data.address = {};
        if (address1) {
          data.address.line1 = address1;
        }
        if (address2) {
          data.address.line2 = address2;
        }
        if (city) {
          data.address.city = city;
        }
        if (state) {
          data.address.state = state;
        }
        if (zip) {
          data.address.zip = zip;
        }
        if (country) {
          data.address.country = country;
        }
      }
      if (phone) {
        data.phone = phone;
      }
      if (hsp._id) {
        data._id = hsp._id;
        updateItem(this.model, data);
      } else {
        createItem(this.model, data);
      }
    }
    return false;
  };

  reset = () => {
    const { hsp } = this.props;
    if (hsp) {
      this.nameField.value = hsp.name ? hsp.name : "";
      this.emailField.value = hsp.email ? hsp.email : "";
      if (hsp.address) {
        this.address1Field.value = hsp.address.line1 ? hsp.address.line1 : "";
        this.address2Field.value = hsp.address.line2 ? hsp.address.line2 : "";
        this.cityField.value = hsp.address.city ? hsp.address.city : "";
        this.stateField.value = hsp.address.state ? hsp.address.state : "";
        this.zipField.value = hsp.address.zip ? hsp.address.zip : "";
        this.countryField.value = hsp.address.country
          ? hsp.address.country
          : "";
      } else {
        this.address1Field.value = "";
        this.address2Field.value = "";
        this.cityField.value = "";
        this.stateField.value = "";
        this.zipField.value = "";
        this.countryField.value = "";
      }
      this.phoneField.value = hsp.phone ? hsp.phone : "";
      if (this.activeField) {
        this.activeField.checked = !!hsp.active;
      }
    } else {
      this.nameField.value = "";
      this.emailField.value = "";
      this.address1Field.value = "";
      this.address2Field.value = "";
      this.cityField.value = "";
      this.stateField.value = "";
      this.zipField.value = "";
      this.countryField.value = "";
      this.phoneField.value = "";
      if (this.activeField) {
        this.activeField.checked = false;
      }
    }
  };

  render = () => {
    const { hsp, form, userGroup, onCancel } = this.props;
    if (!hsp) {
      return null;
    }
    return (
      <div className="ui">
        {form.hasError && (
          <div className="ui error message">
            <ul className="list">
              {form.errorFields.name && <li>Enter a valid name</li>}
              {form.errorFields.email && <li>Enter a valid email address</li>}
              {form.errorFields.address1 && (
                <li>Enter a valid address line 1</li>
              )}
              {form.errorFields.address2 && (
                <li>Enter a valid address line 2</li>
              )}
              {form.errorFields.city && <li>Enter a valid city</li>}
              {form.errorFields.state && <li>Enter a valid state</li>}
              {form.errorFields.zip && <li>Enter a valid zip code</li>}
              {form.errorFields.country && <li>Enter a valid country</li>}
              {form.errorFields.phone && <li>Enter a valid phone</li>}
            </ul>
          </div>
        )}
        <form className="ui form" onSubmit={this.submit}>
          <div className="field">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              tabIndex="10"
              name="name"
              id="name"
              placeholder="Name"
              autoComplete="off"
              autoFocus={true}
              ref={input => (this.nameField = input)}
              defaultValue={hsp.name}
            />
          </div>
          <div className={"field" + (form.errorFields.email ? " error" : "")}>
            <label htmlFor="newEmail">Email</label>
            <input
              type="email"
              tabIndex="20"
              name="email"
              id="email"
              placeholder="Email address"
              autoComplete="off"
              ref={input => (this.emailField = input)}
              defaultValue={hsp.email}
            />
          </div>
          <div className="field">
            <label>Billing address</label>
            <div className="fields">
              <div className="eight wide field">
                <input
                  type="text"
                  tabIndex="30"
                  name="address1"
                  id="address1"
                  placeholder="Building or apartment"
                  ref={input => (this.address1Field = input)}
                  defaultValue={hsp.address ? hsp.address.line1 : ""}
                />
              </div>
              <div className="eight wide field">
                <input
                  type="text"
                  tabIndex="40"
                  name="address2"
                  id="address2"
                  placeholder="Street"
                  ref={input => (this.address2Field = input)}
                  defaultValue={hsp.address ? hsp.address.line2 : ""}
                />
              </div>
            </div>
            <div className="fields">
              <div className="five wide field">
                <input
                  type="text"
                  tabIndex="50"
                  name="city"
                  id="city"
                  placeholder="City"
                  ref={input => (this.cityField = input)}
                  defaultValue={hsp.address ? hsp.address.city : ""}
                />
              </div>
              <div className="four wide field">
                <input
                  type="text"
                  tabIndex="60"
                  name="state"
                  id="state"
                  placeholder="State"
                  ref={input => (this.stateField = input)}
                  defaultValue={hsp.address ? hsp.address.state : ""}
                />
              </div>
              <div className="two wide field">
                <input
                  type="text"
                  tabIndex="70"
                  name="zip"
                  id="zip"
                  placeholder="ZIP code"
                  ref={input => (this.zipField = input)}
                  defaultValue={hsp.address ? hsp.address.zip : ""}
                />
              </div>
              <div className="five wide field">
                <input
                  type="text"
                  tabIndex="80"
                  name="country"
                  id="country"
                  placeholder="country"
                  ref={input => (this.countryField = input)}
                  defaultValue={hsp.address ? hsp.address.country : ""}
                />
              </div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              tabIndex="90"
              name="phone"
              id="phone"
              placeholder="Phone number"
              ref={input => (this.phoneField = input)}
              defaultValue={hsp.phone}
            />
          </div>
          {"admin" === userGroup && (
            <div className="inline field">
              <div className="ui checkbox">
                <input
                  id="active"
                  type="checkbox"
                  tabIndex="100"
                  name="active"
                  ref={input => (this.activeField = input)}
                  defaultChecked={hsp.active}
                />
                <label>Active</label>
              </div>
            </div>
          )}
          <button
            id="cancelHsp"
            tabIndex="210"
            className="ui negative button"
            type="button"
            onClick={onCancel ? onCancel : this.reset}
          >
            Cancel
          </button>
          <button tabIndex="200" className="ui primary button" type="submit">
            Submit
          </button>
        </form>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  form: state.ui.form,
  userGroup: state.auth.user.group
});

export default connect(
  mapStateToProps,
  {
    resetStatusMessage,
    formSetError,
    formResetError,
    createItem,
    updateItem
  }
)(Form);
