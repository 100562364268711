import {
  PHR_READ_REQUEST,
  PHR_READ_SUCCESS,
  PHR_DOWNLOAD_REQUEST,
  PHR_DOWNLOAD_SUCCESS,
  PHR_DOWNLOAD_FAILURE,
  PHR_LAB_SET_DATA,
  PHR_UPDATE_SUCCESS
} from "../actions";

const phr = (
  state = {
    loading: false,
    downloading: false,
    data: null,
    labs: [],
    medicines:[]
  },
  action
) => {
  switch (action.type) {
    case PHR_READ_REQUEST:
      return {
        ...state,
        loading: true,
        data: null
      };

    case PHR_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.data[0]
      };

    case PHR_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.data[0]
      };

    case PHR_DOWNLOAD_REQUEST:
      return { ...state, downloading: true };

    case PHR_DOWNLOAD_SUCCESS:
    case PHR_DOWNLOAD_FAILURE:
      return { ...state, downloading: false };
    case PHR_LAB_SET_DATA:
      const { key, value } = action;
      return { ...state, [key]: value };
    default:
      return state;
  }
};

export default phr;
