// cSpell:ignore noopener, noreferrer
import React from "react";
import "./index.css";

const BottomBar = () => (
  <footer className="ui center aligned segment">
    <div className="ui text">
      <p>Copyright &copy; Maya, Inc.</p>
    </div>
  </footer>
);

export default BottomBar;
