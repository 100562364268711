import { CALL_HTTP_API, CALL_SOCKET_API } from "../middleware/agent-api";

export const AGENT_LOGIN_REQUEST = "AGENT_LOGIN_REQUEST";
export const AGENT_LOGIN_SUCCESS = "AGENT_LOGIN_SUCCESS";
export const AGENT_LOGIN_FAILURE = "AGENT_LOGIN_FAILURE";
export const loginAgent = data => ({
  [CALL_HTTP_API]: {
    types: [AGENT_LOGIN_REQUEST, AGENT_LOGIN_SUCCESS, AGENT_LOGIN_FAILURE],
    endpoint: "agent-login",
    data
  }
});

export const AGENT_LOGOUT_REQUEST = "AGENT_LOGOUT_REQUEST";
export const AGENT_LOGOUT = "AGENT_LOGOUT";
export const logoutAgent = () => ({
  [CALL_SOCKET_API]: {
    types: [AGENT_LOGOUT_REQUEST, AGENT_LOGOUT, AGENT_LOGOUT],
    endpoint: "logoutAgent",
    nonBlocking: true
  }
});

export const AGENT_CONNECTED = "AGENT_CONNECTED";
export const agentConnected = id => ({
  type: AGENT_CONNECTED,
  id
});

export const AGENT_DISCONNECTED = "AGENT_DISCONNECTED";
export const agentDisconnected = id => ({
  type: AGENT_DISCONNECTED,
  id
});

export const RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILURE = "RECOVER_PASSWORD_FAILURE";
export const recoverPassword = data => ({
  [CALL_HTTP_API]: {
    types: [
      RECOVER_PASSWORD_REQUEST,
      RECOVER_PASSWORD_SUCCESS,
      RECOVER_PASSWORD_FAILURE
    ],
    endpoint: "recover-password",
    data
  }
});

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const resetPassword = data => ({
  [CALL_HTTP_API]: {
    types: [
      RESET_PASSWORD_REQUEST,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAILURE
    ],
    endpoint: "reset-password",
    data
  }
});

export const AGENT_AUTOCOMPLETE_REQUEST = "AGENT_AUTOCOMPLETE_REQUEST";
export const AGENT_AUTOCOMPLETE_SUCCESS = "AGENT_AUTOCOMPLETE_SUCCESS";
export const AGENT_AUTOCOMPLETE_FAILURE = "AGENT_AUTOCOMPLETE_FAILURE";
export const autocompleteAgent = (search, count) => ({
  [CALL_SOCKET_API]: {
    types: [
      AGENT_AUTOCOMPLETE_REQUEST,
      AGENT_AUTOCOMPLETE_SUCCESS,
      AGENT_AUTOCOMPLETE_FAILURE
    ],
    endpoint: "readAgent",
    nonBlocking: true,
    data: {
      search: { firstName: search, lastName: search, email: search },
      fields: { _id: 1, firstName: 1, lastName: 1, email: 1 },
      limit: count
    }
  }
});

export const AGENT_READ_REQUEST = "AGENT_READ_REQUEST";
export const AGENT_READ_SUCCESS = "AGENT_READ_SUCCESS";
export const AGENT_READ_FAILURE = "AGENT_READ_FAILURE";
export const readAgent = data => ({
  [CALL_SOCKET_API]: {
    types: [AGENT_READ_REQUEST, AGENT_READ_SUCCESS, AGENT_READ_FAILURE],
    endpoint: "readAgent",
    nonBlocking: true,
    data
  }
});
