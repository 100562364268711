// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel, confirmDeleteItem } from "../../actions/listing";
import AgentSearch from "./AgentSearch";
import Listing from "../Listing";

class AgentListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    user: PropTypes.object.isRequired,
    setItemModel: PropTypes.func.isRequired,
    confirmDeleteItem: PropTypes.func.isRequired
  };

  model = "agent";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history, user, confirmDeleteItem } = this.props,
      fields = {
        serial: { title: "#", type: "serial" },
        avatar: { title: "Avatar", type: "image" },
        firstName: { title: "First name", sortable: true },
        lastName: { title: "Last name", sortable: true },
        email: { title: "Email", sortable: true },
        active: { title: "Active", type: "bool", sortable: true },
        type: { title: "Type", sortable: true }
      },
      actions = [
        {
          name: "Edit",
          type: "primary",
          icon: "edit",
          handler: id => {
            history.push(`/${this.model}/edit/${id}`);
          },
          conditional: record =>
            "hsp" === record.group || "admin" === user.group
        },
        {
          name: "Delete",
          type: "negative",
          icon: "trash",
          handler: id => {
            confirmDeleteItem(id);
          },
          conditional: record =>
            user._id !== record._id &&
            ("hsp" === record.group || "admin" === user.group)
        }
      ];
    if ("hsp" !== user.group) {
      fields.group = { title: "Group", sortable: true };
      fields.hsp = {
        title: "HSP ID",
        link: "/hsp/edit/"
      };
    }
    fields.created = {
      title: "Created",
      type: "date",
      format: "YYYY-MM-DD",
      sortable: true
    };
    fields.actions = { title: "Actions", type: "actions" };
    return (
      <Listing
        title="Agents"
        model={this.model}
        mainField="email"
        // list config
        fields={fields}
        actions={actions}
        highlightFields={{ firstName: 1, lastName: 1, email: 1 }}
        // search form component
        ItemSearch={AgentSearch}
        onAdd={() => {
          history.push(`/${this.model}/add`);
        }}
        simpleSearchTitle="Email"
        getSimpleSearch={search => ({
          email: search
        })}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default withRouter(
  connect(mapStateToProps, {
    setItemModel,
    confirmDeleteItem
  })(AgentListing)
);
