// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setItemModel } from "../../actions/listing";
import { readPhr, formResetError, formSetError, readItem } from "../../actions";
import PhrSearch from "./PhrSearch";
import Listing from "../Listing";
import Header from "../../components/Header";

class PhrListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    setItemModel: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    formResetError: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    readPhr: PropTypes.func.isRequired,
    readItem: PropTypes.func.isRequired,
    phr: PropTypes.object.isRequired
  };

  model = "phr";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const { formSetError, formResetError, readPhr } = this.props,
      civilId = this.civilIdField.value;
    let hasError = false;
    formResetError();
    if (!civilId) {
      formSetError("civilId");
      hasError = true;
    }
    if (!hasError) {
      readPhr(
        {
          search: { civilId: parseInt(civilId) }
        },
        this.viewRecord
      );
    }
    return false;
  };

  viewRecord = () => {
    const { history, phr, formSetError } = this.props,
      phrData = phr.data;

    if (phrData) {
      const id = phrData._id;
      history.push(`/${this.model}/view/${id}`);
    } else {
      formSetError("civilId");
    }
  };

  render() {
    const { history, auth, form } = this.props,
      showTitle = auth.user.type === "Doctor" || auth.user.type === "Nurse",
      fields = {
        serial: { title: "#", type: "serial" },
        civilId: { title: "Civil ID", sortable: true },
        ...(showTitle && { title: "Title" }),
        gender: { title: "Gender" },
        dob: {
          title: "Date of birth",
          type: "date",
          format: "YYYY-MM-DD",
          sortable: true
        },
        created: {
          title: "Created",
          type: "date",
          format: "YYYY-MM-DD",
          sortable: true
        },
        actions: { title: "Actions", type: "actions" }
      },
      actions = [
        {
          name: "View",
          type: "primary",
          icon: "eye",
          handler: id => {
            history.push(`/${this.model}/view/${id}`);
          }
        }
      ],
      showList =
        auth.user.type !== "LabTechnician" && auth.user.type !== "Pharmacist";

    if (showTitle) {
      fields.title = { title: "Title" };
    }

    return (
      <article className="thirteen wide column">
        {showList && (
          <Listing
            title="Personal Health Records"
            model={this.model}
            mainField="civilId"
            // list config
            fields={fields}
            actions={actions}
            highlightFields={{ description: 1 }}
            // search form component
            ItemSearch={PhrSearch}
            simpleSearchTitle="Civil ID"
            getSimpleSearch={search => ({
              civilId: search
            })}
          />
        )}
        {!showList && (
          <div>
            <Header title="Patient" />
            <div className="ui">
              {form.hasError && (
                <div className="ui error message">
                  <ul className="list">
                    {form.errorFields.civilId && <li>Enter a valid civilId</li>}
                  </ul>
                </div>
              )}
              <form className="ui form" onSubmit={this.submit}>
                <div
                  className={
                    "required field" +
                    (form.errorFields.civilId ? " error" : "")
                  }
                >
                  <label htmlFor="civilId">Enter CivilId</label>
                  <input
                    type="text"
                    tabIndex="10"
                    name="civilId"
                    id="civilId"
                    placeholder="civilId"
                    autoComplete="off"
                    autoFocus={true}
                    ref={input => (this.civilIdField = input)}
                  />
                </div>
                <button
                  id="submit"
                  tabIndex="20"
                  className="ui primary button"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}
      </article>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  form: state.ui.form,
  phr: state.phr
});

export default withRouter(
  connect(mapStateToProps, {
    setItemModel,
    readPhr,
    formResetError,
    formSetError,
    readItem
  })(PhrListing)
);
