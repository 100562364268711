import { CALL_SOCKET_API } from "../middleware/agent-api";

export const HSP_AUTOCOMPLETE_REQUEST = "HSP_AUTOCOMPLETE_REQUEST";
export const HSP_AUTOCOMPLETE_SUCCESS = "HSP_AUTOCOMPLETE_SUCCESS";
export const HSP_AUTOCOMPLETE_FAILURE = "HSP_AUTOCOMPLETE_FAILURE";
export const autocompleteHsp = (search, count) => ({
  [CALL_SOCKET_API]: {
    types: [
      HSP_AUTOCOMPLETE_REQUEST,
      HSP_AUTOCOMPLETE_SUCCESS,
      HSP_AUTOCOMPLETE_FAILURE
    ],
    endpoint: "readHsp",
    nonBlocking: true,
    data: {
      search: { name: search },
      fields: { _id: 1, name: 1 },
      limit: count
    }
  }
});

export const HSP_READ_REQUEST = "HSP_READ_REQUEST";
export const HSP_READ_SUCCESS = "HSP_READ_SUCCESS";
export const HSP_READ_FAILURE = "HSP_READ_FAILURE";
export const readHsp = () => ({
  [CALL_SOCKET_API]: {
    types: [HSP_READ_REQUEST, HSP_READ_SUCCESS, HSP_READ_FAILURE],
    endpoint: "readHsp",
    nonBlocking: true
  }
});

export const HSP_UPDATE_REQUEST = "HSP_UPDATE_REQUEST";
export const HSP_UPDATE_SUCCESS = "HSP_UPDATE_SUCCESS";
export const HSP_UPDATE_FAILURE = "HSP_UPDATE_FAILURE";
export const updateHsp = data => ({
  [CALL_SOCKET_API]: {
    types: [HSP_UPDATE_REQUEST, HSP_UPDATE_SUCCESS, HSP_UPDATE_FAILURE],
    endpoint: "updateHsp",
    data
  }
});
