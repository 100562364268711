import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clickTab } from "../../actions/ui/searchBar";

class SearchBar extends Component {
  static propTypes = {
    mainField: PropTypes.string,
    title: PropTypes.string,
    search: PropTypes.object.isRequired,
    SearchForm: PropTypes.func,
    perPage: PropTypes.number.isRequired,
    onSearch: PropTypes.func.isRequired,
    onAdvancedSearch: PropTypes.func.isRequired,
    setPerPage: PropTypes.func.isRequired,
    // Injected by React-Redux
    activeTab: PropTypes.string.isRequired,
    onTabClick: PropTypes.func.isRequired
  };

  search = event => {
    if ("Escape" === event.key) {
      this.clear();
    } else {
      this.props.onSearch(this.searchInput.value.trim());
    }
  };

  clear = () => {
    this.searchInput.value = "";
    this.props.onSearch();
  };

  applyOptions = event => {
    event.preventDefault();
    const perPage = parseInt(this.perPageField.value, 10);
    if (!isNaN(perPage)) {
      this.props.setPerPage(perPage);
    }
  };

  tabClick = tab => {
    const { onTabClick } = this.props;
    this.searchInput.value = "";
    onTabClick(tab);
  };

  render = () => {
    const {
        activeTab,
        title,
        search,
        perPage,
        SearchForm,
        onAdvancedSearch,
        onTabClick
      } = this.props,
      hasSearch = this.searchInput ? !!this.searchInput.value : false,
      enableAdvancedSearch = !!SearchForm;
    return (
      <React.Fragment>
        <div className="ui top attached tabular menu">
          <div className="left menu">
            <div className="item">
              <div className="ui transparent icon input">
                <input
                  id="searchInput"
                  type="text"
                  placeholder={title || "Search"}
                  autoFocus={true}
                  ref={input => (this.searchInput = input)}
                  onFocus={() => onTabClick("")}
                  onKeyUp={this.search}
                />
                {hasSearch && (
                  <i
                    id="clear_search"
                    className="remove circle link icon"
                    onClick={this.clear}
                  />
                )}
                {!hasSearch && <i className="search link icon" />}
              </div>
            </div>
          </div>
          {enableAdvancedSearch && (
            <div
              id="searchTab"
              className={("search" === activeTab ? "active " : "") + "item"}
              onClick={() => this.tabClick("search")}
            >
              Advanced search
            </div>
          )}
          <div
            id="optionsTab"
            className={("options" === activeTab ? "active " : "") + "item"}
            onClick={() => this.tabClick("options")}
          >
            Options
          </div>
        </div>
        {enableAdvancedSearch && "search" === activeTab && (
          <div className="ui bottom attached segment">
            <SearchForm search={search} onSearch={onAdvancedSearch} />
          </div>
        )}
        {"options" === activeTab && (
          <div className="ui bottom attached segment">
            <form className="ui form" onSubmit={this.applyOptions}>
              <div className="field">
                <label htmlFor="perPage">Rows per page</label>
                <select
                  id="perPage"
                  name="perPage"
                  tabIndex="10"
                  ref={input => (this.perPageField = input)}
                  defaultValue={perPage}
                >
                  {[10, 25, 50, 100].map(item => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                  ;
                </select>
              </div>
              <button
                tabIndex="100"
                className="ui primary button"
                type="submit"
              >
                Apply
              </button>
            </form>
          </div>
        )}
      </React.Fragment>
    );
  };
}

const mapStateToProps = state => ({
  activeTab: state.ui.searchBar.activeTab
});

export default connect(
  mapStateToProps,
  {
    onTabClick: clickTab
  }
)(SearchBar);
