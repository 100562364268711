// cSpell:ignore normalizr
import { schema } from "normalizr";

export const item = new schema.Entity("items", {}, { idAttribute: "_id" });
export const items = [item];

export const agent = new schema.Entity("agents", {}, { idAttribute: "_id" });
export const agents = [agent];

export const hsp = new schema.Entity("hsp", {}, { idAttribute: "_id" });
export const hsps = [hsp];
