// cSpell:ignore unmount
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setItemModel } from "../../actions/listing";
import Listing from "../Listing";

class LiveAgentListing extends Component {
  static propTypes = {
    // Injected by React-Redux
    setItemModel: PropTypes.func.isRequired
  };

  model = "liveAgent";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const fields = {
        serial: { title: "#", type: "serial" },
        avatar: { title: "Avatar", type: "image" },
        firstName: { title: "First name", sortable: true },
        lastName: { title: "Last name", sortable: true },
        email: { title: "Email", sortable: true },
        group: { title: "Group", sortable: true },
        hsp: {
          title: "HSP ID",
          link: "/hsp/edit/"
        },
        created: {
          title: "Since",
          type: "date",
          format: "YYYY-MM-DD HH:mm"
        }
      },
      actions = [];
    return (
      <Listing
        title="Live agents"
        model={this.model}
        // list config
        fields={fields}
        actions={actions}
        highlightFields={{ firstName: 1, lastName: 1, email: 1 }}
      />
    );
  }
}

export default connect(
  null,
  {
    setItemModel
  }
)(LiveAgentListing);
