import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { autocomplete, getSuggestedItem } from "../../actions/ui/autocomplete";
import AutocompleteInput from "../AutocompleteInput";

class PhrSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    // Injected by React-Redux
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired
  };

  search = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const title = this.titleField.value.trim(),
      gender = this.genderField.value,
      params = {};
    if (title) {
      params.title = title;
    }
    if (gender) {
      params.gender = gender;
    }
    if (this.hsp) {
      params.hsp = this.hsp;
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.titleField.value = "";
    this.genderField.value = "";
    this.props.onSearch({});
  };

  autocomplete = (search, count) => {
    this.props.autocomplete("hsp", { name: search, active: true }, {}, count);
  };

  render = () => {
    const { search, getSuggestedItem } = this.props;

    return (
      <form className="ui form" onSubmit={this.search}>
        <div className="three fields">
          <div className="field">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              tabIndex="10"
              name="title"
              id="title"
              placeholder="Title"
              autoComplete="off"
              autoFocus={true}
              defaultValue={search.title}
              ref={input => (this.titleField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="gender">Gender</label>
            <select
              id="gender"
              name="gender"
              tabIndex="40"
              defaultValue={search.gender}
              ref={input => (this.genderField = input)}
            >
              <option value="">Select</option>
              {["Male", "Female"].map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
              ;
            </select>
          </div>
          <div className="field">
            <AutocompleteInput
              title="HSP"
              count={3}
              tabIndex={70}
              getSuggestedItem={id => {
                getSuggestedItem("hsp", id, { _id: 1, name: 1 });
              }}
              searchItem={this.autocomplete}
              required={true}
              getName={hsp => hsp.name}
              selectItem={id => {
                this.hsp = id;
              }}
            />
          </div>
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

export default connect(
  null,
  {
    autocomplete,
    getSuggestedItem
  }
)(PhrSearch);
