import { combineReducers } from "redux";
import auth from "./auth";
import ui from "./ui";
import agent from "./agent";
import hsp from "./hsp";
import phr from "./phr";
import listing from "./listing";
import item from "./item";
import { AGENT_LOGOUT } from "../actions";

const appReducer = combineReducers({
  auth,
  ui,
  agent,
  hsp,
  phr,
  listing,
  item
});

const rootReducer = (state, action) => {
  if (AGENT_LOGOUT === action.type) {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
