import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { agentName } from "../../lib/utils";
import { logoutAgent } from "../../actions/agent";
import "./index.css";

const TopBar = ({ auth, logout }) => (
  <header className="ui padded center aligned segment">
    <div className="ui grid">
      <div className="left floated left aligned four wide column">
        <Link to="/" id="topDashboard">
          <img width="24" height="24" src="/logo192.png" alt="Logo" />
        </Link>{" "}
        <h1 className="mainTitle">Maya HSP Portal</h1>
      </div>

      <div className="right floated right aligned twelve wide column">
        <Link to="/account" id="topAccount">
          {agentName(auth.user)}
        </Link>{" "}
        {auth.user.avatar && (
          <img
            alt="Current user"
            className="ui mini circular spaced image"
            src={auth.user.avatar}
          />
        )}{" "}
        {auth.loggedIn && (
          <button id="logout" className="ui button" onClick={logout}>
            Logout
          </button>
        )}
      </div>
    </div>
  </header>
);

TopBar.propTypes = {
  // Injected by React-Redux
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {
    logout: logoutAgent
  }
)(TopBar);
