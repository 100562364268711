import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import LiveAgentListing from "../../containers/LiveAgentListing";
import AgentListing from "../../containers/AgentListing";
import AgentForm from "../../containers/AgentForm";
import Account from "../../containers/Account";
import HspListing from "../../containers/HspListing";
import HspForm from "../../containers/HspForm";
import PhrListing from "../../containers/PhrListing";
import PhrView from "../../containers/PhrView";
import DndListing from "../../containers/DndListing";
import DndForm from "../../containers/DndForm";
import NoMatch from "../NoMatch";

const Routes = ({ isAdmin, isStaff }) => {
  const isAnyStaff = isAdmin || isStaff;
  return (
    <Switch>
      <Route exact path="/phr/view/:id" component={PhrView} />
      <Route exact path="/phr/:page?" component={PhrListing} />
      {isAnyStaff && <Route path="/hsp/add" component={HspForm} />}
      {isAnyStaff && <Route path="/hsp/edit/:id" component={HspForm} />}
      {isAnyStaff && <Route exact path="/hsp/:page?" component={HspListing} />}
      {isAnyStaff && <Route path="/dnd/add" component={DndForm} />}
      {isAnyStaff && <Route exact path="/dnd/:page?" component={DndListing} />}
      {isAnyStaff && (
        <Route exact path="/liveagent/:page?" component={LiveAgentListing} />
      )}
      {isAnyStaff && (
        <Route exact path="/hsp/phr/:hsp" component={PhrListing} />
      )}
      <Route exact path="/account" component={Account} />
      <Route exact path="/agent/add" component={AgentForm} />
      <Route path="/agent/edit/:id" component={AgentForm} />
      <Route exact path="/agent/:page?" component={AgentListing} />
      {isAdmin && <Route path="/dnd/add" component={DndForm} />}
      {isAdmin && <Route exact path="/dnd/:page?" component={DndListing} />}
      {isAnyStaff && (
        <Route exact path="/liveagent/:page?" component={LiveAgentListing} />
      )}
      <Route component={NoMatch} />
    </Switch>
  );
};

Routes.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isStaff: PropTypes.bool.isRequired
};

export default Routes;
