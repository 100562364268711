import React, { Component } from "react";
import PropTypes from "prop-types";

class HspSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired
  };

  search = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const name = this.nameField.value.trim(),
      email = this.emailField.value.trim(),
      status = this.statusField.value,
      params = {};
    if (name) {
      params.name = name;
    }
    if (email) {
      params.email = email;
    }
    if (status) {
      switch (status) {
        case "active":
          params.active = true;
          break;
        case "inactive":
          params.active = false;
          break;
        default:
          break;
      }
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.nameField.value = "";
    this.emailField.value = "";
    this.statusField.value = "";
    this.props.onSearch({});
  };

  render = () => {
    const { search } = this.props,
      status =
        true === search.active
          ? "active"
          : false === search.active
          ? "inactive"
          : "";
    return (
      <form className="ui form" onSubmit={this.search}>
        <div className="three fields">
          <div className="field">
            <label htmlFor="name">HSP</label>
            <input
              type="text"
              tabIndex="10"
              name="name"
              id="name"
              placeholder="HSP name"
              autoComplete="off"
              autoFocus={true}
              defaultValue={search.name}
              ref={input => (this.nameField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              tabIndex="20"
              name="email"
              id="email"
              placeholder="Email address"
              autoComplete="off"
              defaultValue={search.email}
              ref={input => (this.emailField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              tabIndex="30"
              defaultValue={status}
              ref={input => (this.statusField = input)}
            >
              <option value="">Select</option>
              {["active", "inactive"].map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
              ;
            </select>
          </div>
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

export default HspSearch;
