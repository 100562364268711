import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const LeftBar = ({ isAdmin, isAnyStaff, isOther }) => (
  <nav className="ui left fixed vertical fluid menu three wide column">
    {!isOther && (
      <NavLink to="/phr" exact={true} id="navPhr" className="item">
        PHRs
      </NavLink>
    )}
    {isOther && (
      <NavLink to="/phr" exact={true} id="navPhr" className="item">
        Patient
      </NavLink>
    )}
    <NavLink to="/account" id="navAccount" className="item">
      My Account
    </NavLink>
    <NavLink to="/agent" id="navAgent" className="item">
      Agents
    </NavLink>
    {isAnyStaff && (
      <NavLink to="/hsp" id="navHsp" className="item">
        HSPs
      </NavLink>
    )}
    {isAnyStaff && (
      <NavLink to="/dnd" id="navDnd" className="item">
        DND
      </NavLink>
    )}
    {isAnyStaff && (
      <NavLink to="/liveagent" id="navLiveagent" className="item">
        Online Agents
      </NavLink>
    )}
  </nav>
);

LeftBar.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isAnyStaff: PropTypes.bool.isRequired,
  isOther: PropTypes.bool.isRequired
};

export default LeftBar;
