import {
  AGENT_LOGIN_SUCCESS
} from "../actions/agent";

const auth = (state = { loggedIn: false, user: {} }, action) => {
  switch (action.type) {
    case AGENT_LOGIN_SUCCESS:
      if (action.response.ok) {
        return { ...state, user: action.response.agent, loggedIn: true };
      }
      return state;
    default:
      return state;
  }
};

export default auth;
