import {
  HSP_READ_REQUEST,
  HSP_READ_SUCCESS,
  HSP_READ_FAILURE,
  HSP_UPDATE_SUCCESS,
  ITEM_UPDATE_SUCCESS
} from "../actions";

const hsp = (
  state = {
    loading: false,
    data: null
  },
  action
) => {
  switch (action.type) {
    case HSP_READ_REQUEST:
      return { ...state, loading: true };
    case HSP_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.data[0]
      };
    case ITEM_UPDATE_SUCCESS:
      if ("hsp" === action.model) {
        return {
          ...state,
          data: action.response.data
        };
      }
      return state;
    case HSP_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.response.data
      };
    case HSP_READ_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default hsp;
