import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import PrintProvider, { Print } from "react-easy-print";
import {
  readPhr,
  downloadPdfPhr,
  setLabData,
  formSetError,
  formResetError,
  updateItem,
  updatePhr
} from "../../actions";
import SimpleHeader from "../../components/SimpleHeader";
import Colors from "./Colors";

class PhrView extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    phr: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    readPhr: PropTypes.func.isRequired,
    downloadPdfPhr: PropTypes.func.isRequired,
    setLabData: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    updateItem: PropTypes.func.isRequired,
    updatePhr: PropTypes.func.isRequired
  };

  state = { moreLabs: false };
  model = "phr";

  componentWillMount = () => {
    const { history, match } = this.props;
    if (match.params.id) {
      this.phrRead(match.params.id);
    } else {
      history.push("/phr");
    }
  };

  printPhr = () => {
    if (this.printTimer) {
      clearTimeout(this.printTimer);
    }
    this.printTimer = setTimeout(() => {
      this.printTimer = null;
      window.print();
    }, 100);
  };

  downloadPhr = () => {
    const { phr, downloadPdfPhr } = this.props;
    downloadPdfPhr(phr.data._id);
  };

  addMoreLabs = () => {
    this.setState({ moreLabs: true });
  };

  orderLab = () => {
    const {
        setLabData,
        phr,
        formSetError,
        formResetError,
        updateItem
      } = this.props,
      lab = this.labField.value,
      labArray = [...phr.data.labs];
    let hasError = false;
    formResetError();
    if (!lab) {
      formSetError("lab");
      hasError = true;
    }
    if (!hasError) {
      labArray.push(lab);
      setLabData("labs", labArray);
      this.labField.value = "";
      const data = {
        _id: phr.data._id,
        civilId: phr.data.civilId,
        labs: labArray
      };
      updateItem(this.model, data);
      alert(lab + " has been Ordered.");
    }
    this.phrRead(phr.data._id);
  };

  addToOrder = element => {
    const { setLabData, phr, updateItem } = this.props,
      labArray = [...phr.data.labs];
    let hasError = false,
      exist = false;

    if (labArray.length > 0) {
      labArray.forEach(lab => {
        if (element.value === lab.value) {
          alert(element.value + " Already added.");
          hasError = true;
        } else {
          exist = true;
        }
      });
    } else {
      labArray.push(element);
    }

    if (exist) {
      labArray.push(element);
    }

    if (!hasError) {
      setLabData("labs", labArray);
      const data = {
        _id: phr.data._id,
        civilId: phr.data.civilId,
        labs: labArray
      };
      alert(element.value + " has been Ordered.");
      updateItem(this.model, data);
    }

    this.phrRead(phr.data._id);
  };

  orderMedicine = () => {
    const {
        setLabData,
        phr,
        formSetError,
        formResetError,
        updateItem
      } = this.props,
      medicine = this.medicineField.value,
      medicineArray = [...phr.data.medicines];
    let hasError = false;
    formResetError();
    if (!medicine) {
      formSetError("medicine");
      hasError = true;
    }
    if (!hasError) {
      medicineArray.push(medicine);
      setLabData("medicines", medicineArray);
      this.medicineField.value = "";
      const data = {
        _id: phr.data._id,
        civilId: phr.data.civilId,
        medicines: medicineArray
      };
      updateItem(this.model, data);
      alert(medicine + " has been Ordered.");
    }
    this.phrRead(phr.data._id);
  };

  phrRead = id => {
    const { readPhr } = this.props;
    readPhr({
      search: { _id: id },
      populate: [{ path: "hsp", select: { _id: 0, name: 1 } }]
    });
  };

  render = () => {
    const { history, phr, auth, form } = this.props,
      { data: phrData, downloading } = phr,
      showTitle = auth.user.type === "Doctor" || auth.user.type === "Nurse";

    if (!phrData) {
      return null;
    }

    let noteItems = JSON.parse(phrData.notes),
      phrLabs = phrData.labs,
      phrMedicines = phrData.medicines;

    if (auth.user.type === "Receptionist") {
      noteItems = noteItems.filter(item => {
        return item.key === "triage" || item.key === "person";
      });
    }

    return (
      <article className="thirteen wide column">
        <PrintProvider>
          <div className="ui segment">
            <Print name="phrPrint">
              <SimpleHeader title="Personal Health Record" />
              <table className="ui basic table">
                <tbody>
                  <tr>
                    <td>
                      <strong>PHR #</strong>
                    </td>
                    <td>{phrData._id}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Civil ID</strong>
                    </td>
                    <td>{phrData.civilId}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Health Service Provider</strong>
                    </td>
                    <td>{phrData.hsp.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Created</strong>
                    </td>
                    <td>{moment(phrData.created).format("YYYY-MM-DD")}</td>
                  </tr>
                  {showTitle && (
                    <tr>
                      <td>
                        <strong>Title</strong>
                      </td>
                      <td>{phrData.title}</td>
                    </tr>
                  )}
                  {!!phrData.dob && (
                    <tr>
                      <td>
                        <strong>Date of birth</strong>
                      </td>
                      <td>{moment(phrData.dob).format("YYYY-MM-DD")}</td>
                    </tr>
                  )}
                  {!!phrData.gender && (
                    <tr>
                      <td>
                        <strong>Sex</strong>
                      </td>
                      <td>{phrData.gender}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div>
                <strong>Notes:</strong>
              </div>
              <br />
              {auth.user.type === "LabTechnician" && (
                <div>
                  {phrLabs.length > 0 && (
                    <div>
                      <b>Labs and Radiology Recommendations</b>
                    </div>
                  )}

                  {phrLabs.map((lab, index) => (
                    <div key={lab.key ? lab.key : index}>
                      {lab.name} <span>{lab.value ? lab.value : lab}</span>
                    </div>
                  ))}
                </div>
              )}
              {auth.user.type === "Pharmacist" && (
                <div>
                  {phrMedicines.length > 0 && (
                    <div>
                      <b>Medicines</b>
                    </div>
                  )}

                  {phrMedicines.map((medicine, index) => (
                    <div key={index}>
                      <span>{medicine}</span>
                    </div>
                  ))}
                </div>
              )}
              {auth.user.type !== "LabTechnician" &&
                auth.user.type !== "Pharmacist" && (
                  <div>
                    {noteItems.map(item => (
                      <div key={item.key}>
                        <div>
                          <b>{item.title}</b>
                        </div>
                        <div>
                          {item.data.map(element => (
                            <div key={element.key}>
                              {element.name}{" "}
                              <span
                                style={
                                  "complaints" === item.key &&
                                  element.key.indexOf("symptom") === -1
                                    ? { color: "grey" }
                                    : {}
                                }
                              >
                                {item.title ===
                                  "Lab and Radiology Recommendations" &&
                                auth.user.type === "Doctor" &&
                                element.key.indexOf("lab_") > -1 ? (
                                  <table style={{ width: "40%" }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ width: "30%" }}>
                                          {element.value}
                                        </td>
                                        <td>
                                          <button
                                            id="addToOrder"
                                            tabIndex="20"
                                            className="ui mini primary button"
                                            onClick={() =>
                                              this.addToOrder(element)
                                            }
                                          >
                                            Add To Order
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                ) : (
                                  element.value
                                )}

                                {item.title === "Differential Diagnosis" && (
                                  <div className="ui progress">
                                    <div
                                      className="bar"
                                      style={{
                                        width: element.progress + "%",
                                        height: "10px",
                                        background:
                                          element.lifeThreatening !== 0
                                            ? Colors.lifeThreatingText
                                            : Colors.progressBarBackGround
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                        <br />
                      </div>
                    ))}
                  </div>
                )}
            </Print>

            {auth.user.type === "Doctor" && phrLabs.length > 0 && (
              <div>
                <div>
                  <b>Ordered Labs</b>
                </div>
                {phrLabs.map((lab, index) => (
                  <div key={lab.key ? lab.key : index}>
                    <div>
                      {lab.name} <span>{lab.value ? lab.value : lab}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {form.hasError && form.errorFields.lab && (
              <div className="ui error message">
                <ul className="list">
                  {form.errorFields.lab && <li>Enter Lab</li>}
                </ul>
              </div>
            )}

            {auth.user.type === "Doctor" && (
              <div>
                <div className="ui hidden divider" />
                <div>
                  <b>Order Lab</b>
                </div>
                <div
                  className={
                    "required field" + (form.errorFields.lab ? " error" : "")
                  }
                >
                  <input
                    type="text"
                    tabIndex="10"
                    name="lab"
                    id="lab"
                    autoComplete="off"
                    autoFocus={true}
                    ref={input => (this.labField = input)}
                  />
                  <button
                    id="add"
                    tabIndex="20"
                    className="mini ui primary button"
                    onClick={this.orderLab}
                    style={{ marginLeft: 25 }}
                  >
                    Order
                  </button>
                </div>
              </div>
            )}
            {auth.user.type === "Doctor" && phrMedicines.length > 0 && (
              <div>
                <div className="ui hidden divider" />
                <div>
                  <b>Ordered Medicines</b>
                </div>
                {phrMedicines.map((medicine, index) => (
                  <div key={index}>
                    <div>
                      <span>{medicine}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {form.hasError && form.errorFields.medicine && (
              <div className="ui error message">
                <ul className="list">
                  {form.errorFields.medicine && <li>Enter Medicine</li>}
                </ul>
              </div>
            )}

            {auth.user.type === "Doctor" && (
              <div>
                <div className="ui hidden divider" />
                <div>
                  <b>Order Medicine</b>
                </div>
                <div
                  className={
                    "required field" +
                    (form.errorFields.medicine ? " error" : "")
                  }
                >
                  <input
                    type="text"
                    tabIndex="10"
                    name="medicine"
                    id="medicine"
                    autoComplete="off"
                    autoFocus={true}
                    ref={input => (this.medicineField = input)}
                  />
                  <button
                    id="orderMedicine"
                    tabIndex="20"
                    className="mini ui primary button"
                    onClick={this.orderMedicine}
                    style={{ marginLeft: 25 }}
                  >
                    Order
                  </button>
                </div>
              </div>
            )}

            <div className="ui hidden divider" />
            <button
              id="goBack"
              type="button"
              tabIndex="20"
              className="ui primary button"
              onClick={() => {
                history.push("/phr");
              }}
            >
              Go back
            </button>
            <button
              id="download"
              type="button"
              tabIndex="30"
              className={"ui button" + (downloading ? " loading" : "")}
              onClick={this.downloadPhr}
            >
              Download
            </button>
            <button
              id="print"
              type="button"
              tabIndex="10"
              className="ui positive button"
              onClick={this.printPhr}
            >
              Print
            </button>
            <div className="ui info message">
              <i className="flag blue icon" />
              Print works best in Chrome and Firefox web browsers.
            </div>
          </div>
        </PrintProvider>
      </article>
    );
  };
}

const mapStateToProps = state => ({
  phr: state.phr,
  auth: state.auth,
  form: state.ui.form
});

export default withRouter(
  connect(mapStateToProps, {
    readPhr,
    downloadPdfPhr,
    setLabData,
    formSetError,
    formResetError,
    updateItem,
    updatePhr
  })(PhrView)
);
